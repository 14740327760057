/* You can add global styles to this file, and also import other style files */
@import 'styles/reset.scss';

html,
body {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  margin: 0;
  background: #0e0f0f;
  color: white;
}

html * {
  font-family: 'Mulish', sans-serif!important;
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Mulish:wght@200;300;400;500;600;700;800&display=swap');

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

img {
  vertical-align: middle;
}
